<template>
  <v-container fluid>
    <TableViewComponent
      title="Table ticketing > statuts spécifiques"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter un statut spécifique"
      :itemLabel="textNumberOfItemsSingle"
      :itemsLabel="textNumberOfItemsMultiple"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce statut spécifique ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
      :disableAddAction="!selectedApp"
      :viewTable="showTable"
    >
      <template v-slot:beforetable>
        <v-card-text>
          <div class="d-flex">
            <!-- Les applications -->
            <v-autocomplete
              v-model="selectedApp"
              :items="apps"
              item-text="label"
              return-object
              placeholder="Choisir une application"
              class="mr-4 my-0 pa-0"
              no-data-text="aucune application"
              @change="onSelectedAppChange()"
              clearable
            >
            </v-autocomplete>
            <v-spacer></v-spacer>
          </div>
        </v-card-text>

        <v-card-text v-if="!showTable && selectedApp">
          <div 
          class="mb-6 font-weight-bold"
          >
            {{ messageStatutByDefault }}
          </div>
        </v-card-text>
      </template>   
    
    </TableViewComponent>

    <!-- Alert désassociation statut spécifiques -->
    <StandardDialogConfirmed
    title="Confirmez votre action"
    label= "Vous allez ré-associer l'ensemble des statuts par défaut."
    :labelHtml="true"
    :visible.sync="showStandardDialog"
    :item="specificStatutToDelete"
    @confirmed="deleteSpecificStatus()" >
    </StandardDialogConfirmed>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";
import StandardDialogConfirmed from "@/components/ui/StandardDialogConfirmed.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { TicketingStatusService } from "@/service/conf/ticketing_status_service.js";
import { ApplicationService } from "@/service/dictionary/applications_service.js";
import { TicketingObjectSubobjectService } from "@/service/conf/ticketing_object_subobject_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableTicketingStatus",
  components: { TableViewComponent, StandardDialogConfirmed, },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /**le service d'accès */
      service: null,
      /** le service des applications */
      serviceApplication: null,
      /** Le service des applications ticketing */
      serviceAppTicketing: null,

      /** l'application sélectionnée */
      selectedApp: null,
      /** la liste des applications de la bd */
      apps: [],
      /** la liste des apps du ticketing */
      appsTicketing: [],

      /** Les statuts par défaut */
      defaultStatus: [],

      /** Possibilité de cacher le composant table */
      showTable: false,

      /** Affichage de la popup */
      showStandardDialog: false,

      /** le statut spécifique à supprimer */
      specificStatutToDelete: null,

      /** Message pour les applications qui n'ont pas de statut spécifiques */
      messageStatutByDefault: "Cette application n'a pas de statut spécifique mais ceux par défaut.",

      /** Texte pour le titre compteur de statut */
      textNumberOfItemsSingle: "statut",
      textNumberOfDefaultStatusSingle: "statut par défaut",
      textNumberOfSpecificStatusSingle: "statut spécifique",
      textNumberOfItemsMultiple: "statuts",
      textNumberOfDefaultStatusMultiple: "statuts par défaut",
      textNumberOfSpecificStatusMultiple: "statuts spécifiques",

    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() { 
      this.$router.push(routes.ticketingSpecificStatus.add.path + "?app="+ this.selectedApp.id);
    },

    async load() {
      try {
        this.loading = true;

        this.entities = [];

        // Récupération de l'ensemble des applications
        let apps = await this.serviceApplication.getAll();
        /** Récupération de tous les statut par défaut */
        this.defaultStatus = await this.service.getAllDefaultStatus();
        /** Récupération de toutes les apps qui sont dans le ticketing */
        this.appsTicketing = await this.serviceAppTicketing.getAllApps();

        // Matching entre liste des apps et la liste des apps qui sont ticketing
        this.apps = apps.filter((a) => this.appsTicketing.includes(a.id));

        if (this.selectedApp) {
          this.onSelectedAppChange();
        }

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Changement d'application */
    async onSelectedAppChange() {
      this.entities = [];
      
      // Pas d'application sélectionnée, on remet tout a zéro
      if (!this.selectedApp) {
        this.showTable = false;
        this.textNumberOfItemsSingle = "statut";
        this.textNumberOfItemsMultiple = "statuts";
        return;
      }

      try {
        this.loading = true;

        /** Récupération des statuts de l'application sélectionné */
        let statusApp = await this.serviceAppTicketing.getAllStatusOfApplication(this.selectedApp.id);
        // check si app a les statuts par défault ou spécifiques
        let appStatusDefault = this.checkAppHasDefaultStatus(statusApp);
        
        // L'application as les statut par défaut
        if (appStatusDefault) {
          this.showTable = false;
          this.entities = this.defaultStatus;
          this.textNumberOfItemsSingle = this.textNumberOfDefaultStatusSingle;
          this.textNumberOfItemsMultiple = this.textNumberOfDefaultStatusMultiple;
        } else {
          // L'application as des statuts spécifiques
          this.showTable = true;
          this.textNumberOfItemsSingle = this.textNumberOfSpecificStatusSingle;
          this.textNumberOfItemsMultiple = this.textNumberOfSpecificStatusMultiple;

          // Parcours des statuts spécifiques
          statusApp.forEach((e) => {
            let entity = JSON.parse(JSON.stringify(e));

            // Route pour la visualisation du ticket avec identifiant de l'app
            entity.view = () => {
              this.$router.push(routes.ticketingSpecificStatus.view.root + "/" + entity.id
              + "?app="+ this.selectedApp.id);
            };

            // action de modification
            entity.edit = () => {
              this.$router.push(routes.ticketingSpecificStatus.edit.root + "/" + entity.id
              + "?app="+ this.selectedApp.id);
            };

            // action de suppression d'un statut
            entity.delete = async () => {
              try {
                this.specificStatutToDelete = entity;
                
                if (!this.checkAppHasDefaultStatus(this.entities) && this.entities.length == 1) {
                  this.showStandardDialog = true;
                } else {
                  this.deleteSpecificStatus();
                }
              } catch (error) {
                console.error(error);
                this.addErrorToSnackbar(
                  "suppression du statut : " +
                    (exceptions.toMessage(error) || "problème technique")
                );
              }
            };
            this.entities.push(entity);
          });
          
          // Tri des statuts par défaut par "ordre"
          this.entities.sort(function(a, b) {
            return a.ranking - b.ranking;
          });

          // Construction des colonnes de la table
          this.buildColumns();
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Suppression du statut spécifique */
    async deleteSpecificStatus() {
      await this.serviceAppTicketing.disassociateStatusOfApp(this.selectedApp.id, this.specificStatutToDelete.id);
      await this.service.delete(this.specificStatutToDelete.id);
      // Suppression du statu dans la liste des entités
      this.entities.splice(this.entities.findIndex((e) => this.specificStatutToDelete.id == e.id), 1);

      // check si app as toujours un status spé, sinon réaffecte tous les status par défaut
      if (this.entities.length == 0) {
        // Parcours de l'ensemble des statuts par défaut pour réaffectation
        for (let defStatus of this.defaultStatus) {
          await this.serviceAppTicketing.associateStatutToApp(this.selectedApp.id, defStatus.id);
        }
      }

      this.load();
    },

    /** Analyse si l'application a les status par défaut ou non */
    checkAppHasDefaultStatus(statusApp) {
      let statutDefault = true;

      // Parcours la liste des status de l'application 
      for (let status of statusApp) {
        // Tente de trouver le statut par défaut correspondant
        let found = this.defaultStatus.find((d) => d.id == status.id);

        if (!found) {
          statutDefault = false;
          break;
        }
      }

      return statutDefault;
    },

    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {

      let columns = [
        {
          text: "Label",
          align: "start",
          sortable: false,
          value: "label",
        },
        {
          text: "Ordre",
          align: "start",
          sortable: true,
          value: "ranking",
        },
        {
          text: "Clé technique",
          align: "start",
          sortable: true,
          value: "tid",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];          

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditTicketingSpecificStatus];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditTicketingSpecificStatus, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadTicketingSpecificStatus];
    },
  },
  mounted() {
    //on instancie les services
    this.service = new TicketingStatusService(this.$api.getTicketingStatus());
    this.serviceApplication = new ApplicationService(this.$api.getApplicationApi()); 
    this.serviceAppTicketing = new TicketingObjectSubobjectService(
      this.$api.getTicketingObjectSubobject()
    );

    this.load();
  },
  
};
</script>

<style>
</style>