import * as statusMapper from "@/service/error_request_mapper.js";

export class TicketingObjectSubobjectService {
  constructor(api) {
    this.api = api;
  }



/////////////////////////// GET ///////////////////////////

  async getAll() {
    return this.api
      .getAll()
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /** Récupération d'un objet par son id */
  async getObjectById(appId, serviceId, objectId) {
    return this.api
      .getObjectById(appId, serviceId, objectId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(objectId);
        converter.convert(error);
      });
  }


  /** Récupère l'ensemble des applications de la conf */
  async getAllApps() {
    return this.api
      .getAllApps() 
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /** Récupère tous les status d'une applications */
  async getAllStatusOfApplication(appId) {
    return this.api.getAllStatusOfApplication(appId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Récupère tous les services d'un app */
  async getAllServicesFromApp(appId) {
    return this.api
      .getAllServicesFromApp(appId) 
      // .then((data) => {
      //   data.sort(function(a, b) {
      //     return a.name.localCompare(b.name);
      //   });
      //   return data;
      // })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /** Récupère tous les objets à partir d'un service */
  async getAllObjectsOfService(appId, serviceId) {
    return this.api
      .getAllObjectsOfService(appId, serviceId) 
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }


  /** Récupère tous les sous-objets à partir d'un objet */
  async getAllSubobjects(appId, serviceId, objectId) {
    return this.api
      .getAllSubobjects(appId, serviceId, objectId) 
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  } 


////////////////////////// CREATE //////////////////////////

  
  async createObject(appId, serviceId, object) {
    return this.api
      .createObject(appId, serviceId, object)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(object.label);
        converter.add409AlreadyExists(object.label);
        converter.convert(error);
    });
  }
  
  /**
   * Ajout d'un sous-objet à l'object en param
   */
  async createSubObject(appId, serviceId, objectId, subobject) {
    return this.api
    .createSubObject(appId, serviceId, objectId, subobject)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(objectId);
      converter.convert(error);
    });
  }
  
  /** Ajout d'un statut spécifique à une application */
  async createSpecificStatutToApp(appId, entity) {
    return this.api
      .createSpecificStatutToApp(appId, entity)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(entity.label);
        converter.add409AlreadyExists(entity.label);
        converter.convert(error);
    });
  }

  /** Associer un statut spécifique à une application */
  async associateStatutToApp(appId, statusId) {
    return this.api
      .associateStatutToApp(appId, statusId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(statusId);
        converter.add409AlreadyExists(statusId);
        converter.convert(error);
    });
  }


////////////////////////// UPDATE //////////////////////////

  /** Met à jour un objet */
  async updateObject(appId, serviceId, object) {
    return this.api
      .updateObject(appId, serviceId, object)
      .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(object.id);
      converter.convert(error);
    });
  }


  /** Met à jour un sous-objet */
  async updateSubobject(appId, serviceId, objectId, subobject) {
    return this.api
      .updateSubObject(appId, serviceId, objectId, subobject)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(subobject.id);
        converter.convert(error);
    });
  }



////////////////////////// DELETE //////////////////////////

  /** Suppression d'un objet  */
  async deleteObject(appId, serviceId, objectId) {
    return this.api
      .deleteObject(appId, serviceId, objectId)
      .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(objectId);
      converter.convert(error);
    });
  }

  /**
   * Suppression d'un sous-objet pour l'objet indiqué
   */
  async deleteSubobject(appId, serviceId, objectId, subobjectId) {
    return this.api
      .deleteSubobject(appId, serviceId, objectId, subobjectId)
      .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(subobjectId);
      converter.convert(error);
    });
  }

  /**
   * Désassociation entre une application et son statut
   */
  async disassociateStatusOfApp(appId, statusId) {
    return this.api.disassociateStatusOfApp(appId, statusId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(statusId);
      converter.convert(error);
    });
  }


}
