var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("TableViewComponent", {
        attrs: {
          title: "Table ticketing > statuts spécifiques",
          addItemLabel: "ajouter un statut spécifique",
          itemLabel: _vm.textNumberOfItemsSingle,
          itemsLabel: _vm.textNumberOfItemsMultiple,
          items: _vm.entities,
          columns: _vm.buildColumns(),
          deleteItemLabel: "Voulez-vous supprimer ce statut spécifique ?",
          loading: _vm.loading,
          rolesForEdition: _vm.rolesForEdition,
          rolesForAddDelete: _vm.rolesForAddDelete,
          rolesForRead: _vm.rolesForRead,
          disableAddAction: !_vm.selectedApp,
          viewTable: _vm.showTable,
        },
        on: {
          addItemEvent: function ($event) {
            return _vm.onAddElement()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "beforetable",
            fn: function () {
              return [
                _c("v-card-text", [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mr-4 my-0 pa-0",
                        attrs: {
                          items: _vm.apps,
                          "item-text": "label",
                          "return-object": "",
                          placeholder: "Choisir une application",
                          "no-data-text": "aucune application",
                          clearable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSelectedAppChange()
                          },
                        },
                        model: {
                          value: _vm.selectedApp,
                          callback: function ($$v) {
                            _vm.selectedApp = $$v
                          },
                          expression: "selectedApp",
                        },
                      }),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ]),
                !_vm.showTable && _vm.selectedApp
                  ? _c("v-card-text", [
                      _c("div", { staticClass: "mb-6 font-weight-bold" }, [
                        _vm._v(" " + _vm._s(_vm.messageStatutByDefault) + " "),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("StandardDialogConfirmed", {
        attrs: {
          title: "Confirmez votre action",
          label: "Vous allez ré-associer l'ensemble des statuts par défaut.",
          labelHtml: true,
          visible: _vm.showStandardDialog,
          item: _vm.specificStatutToDelete,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showStandardDialog = $event
          },
          confirmed: function ($event) {
            return _vm.deleteSpecificStatus()
          },
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }