import * as statusMapper from "@/service/error_request_mapper.js";

export class TicketingStatusService {
  constructor(api) {
    this.api = api;
  }

  /////////////////////////// GET ///////////////////////////
  
  /** Récupère l'ensemble des statuts*/
  async getAll() {
    return this.api.getAllDefaultStatus()
    .then((data) => {
      data.sort(function(a, b) {
        return a.label.localeCompare(b.label);
      });
      return data;
    })
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Récupère l'ensemble des statuts par défaut */
  async getAllDefaultStatus() {
    return this.api.getAllDefaultStatus()
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    }); 
  }

  /** Récupère un statut par son identifiant */
  async getById(id) {
    return this.api
      .getById(id)

      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(id);
        converter.convert(error);
      });
  }
/////////////////////////// POST ///////////////////////////
  
  async create(entity) {
    return this.api.create(entity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entity.id);
      converter.add409AlreadyExists(entity.label);
      converter.convert(error);
    });
  }

  /////////////////////////// PUT ///////////////////////////
  
  async update(entity) {
    return this.api.update(entity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entity.id);
      converter.convert(error);
    });
  }


/////////////////////////// DELETE ///////////////////////////
  
  async delete(id) {
    return this.api.delete(id).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(id);
      converter.convert(error);
    });
  }

 
  






}