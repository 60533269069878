<template>
  <v-dialog v-model="show"
     width="400px"
  >
    <v-card>
      <v-card-title class="mb-4">
        {{ title }}
      </v-card-title>

      <v-card-text 
      v-if="!labelHtml">
        {{ label }}
      </v-card-text>

      <v-card-text 
      v-if="labelHtml">
        <span v-html="label"></span>
      </v-card-text>

      <v-card-text class="mb-4">
        Êtes-vous sûr de vouloir continuer cette action ?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          color="primary"
          text
          @click="iDontConfirmed()"
          class="btn ma-2 px-4"
        >
          Non
        </v-btn>
        
        <v-btn 
          color="primary"  
          @click="iConfirmed()"
          class="btn ma-2 px-4"
        >
          Oui
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StandardDialogConfirmed",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "-",
    },
    label: {
      type: String,
      default: "-",
    },
    labelHtml: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      show: this.visible,
    };
  },
  methods: {
    /**l'action n'est pas confirmée. on ferme la dialog.*/
    iDontConfirmed() {
      this.close();
    },
    /**l'action est confirmée.*/
    iConfirmed() {
      this.$emit("confirmed", this.item);
      this.close();
    },

    /**Fermer le dialog */
    close() {
      this.show = false;
      this.$emit("update:visible", false);
    },
  },
  watch: {
    visible(value) {
      this.show = value;
    },
    show(value) {
      //on ferme le dialog, il faut informer que le composant n'est plus visible
      if (!value) {
        this.$emit("update:visible", false);
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>